import React from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <Link
              className="navbar-brand"
              to="/"
              style={{
                margin: "0rem 32rem 0rem 0rem",
                color: "#000",
                textDecoration: "none",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#96031A";
                e.target.style.textDecoration = "none";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#000";
                e.target.style.textDecoration = "none";
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/logo2.png"}
                width="140"
                height="80"
                className="d-inline-block align-text-top"
                style={{ margin: "0.2rem" }}
                alt="favicon?"
              />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li
                  className="nav-item"
                  style={{ padding: "0rem 1rem 0rem 0rem" }}
                >
                  <Link
                    className="nav-link"
                    to="/"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#96031A";
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#000";
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li
                  className="nav-item"
                  style={{ padding: "0rem 1rem 0rem 0rem" }}
                >
                  <a
                    className="nav-link"
                    href="https://snackdelight.in/products.html"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#96031A";
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#000";
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    Products
                  </a>
                </li>
                <li
                  className="nav-item"
                  style={{ padding: "0rem 1rem 0rem 0rem" }}
                >
                  <a
                    className="nav-link"
                    href="https://snackdelight.in/contact.html"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#96031A";
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#000";
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    Contact
                  </a>
                </li>
                <li
                  className="nav-item"
                  style={{ padding: "0rem 1rem 0rem 0rem" }}
                >
                  <a
                    className="nav-link"
                    href="#About"
                    style={{
                      color: "#000",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.color = "#96031A";
                      e.target.style.textDecoration = "none";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.color = "#000";
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    About
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div
          style={{
            backgroundColor: "#ffc614",
            color: "#ffc614",
            height: "0.3rem",
            fontSize: "0.2rem",
          }}
        />
        <div
          style={{
            backgroundColor: "#750f34",
            color: "#750f34",
            height: "0.3rem",
            fontSize: "0.2rem",
          }}
        />
      </div>
    </>
  );
}

export default Navbar;
