import React from "react";

const GallerySection = () => {
  const galleryImages = [
    process.env.PUBLIC_URL + "/assets/G1.jpeg",
    process.env.PUBLIC_URL + "/assets/G2.jpeg",
    process.env.PUBLIC_URL + "/assets/G3.jpeg",
    process.env.PUBLIC_URL + "/assets/G4.jpeg",
    process.env.PUBLIC_URL + "/assets/G5.jpeg",
    process.env.PUBLIC_URL + "/assets/G6.jpg",
  ];

  const imageStyle = {
    width: "350px", // Set your desired width
    height: "450px", // Set your desired height
    margin: "20px", // Adjust the margin as needed
    objectFit: "cover", // Ensure the image covers the defined area
    borderRadius: "1rem",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          height: "0.1rem",
          fontSize: "0.1rem",
        }}
      >
        dg
      </div>
      <div style={{ margin: "1rem 1rem 1.5rem 1rem" }}>
        <h2 style={{ margin: "2rem 0rem 1.2rem 2.3rem" }}>
          <span
            className="card-title"
            style={{
              fontSize: "2rem",
              fontStyle: "italic",
            }}
          >
            Gallery
          </span>
        </h2>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection:"row",justifyContent:"space-evenly" }}>
          {galleryImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              style={imageStyle}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default GallerySection;
