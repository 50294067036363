import React from "react";

const About = () => {
  const sectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "2rem",
    backgroundColor: "#f7f7f7",
    position: "relative",
  };

  const contentStyle = {
    flex: 1,
  };

  const imageStyle = {
    maxWidth: "50%",
    float: "right",
    margin: "0 0 1rem 1rem",
  };

  // Additional styles for mobile responsiveness
  const mobileImageStyle = {
    display: "block",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "1.5rem", // Use "borderRadius" with a capital "R"
  };

  return (
    <section style={sectionStyle} id="about">
      <div style={contentStyle}>
        <h2 style={{ margin: "0rem 0rem 1.5rem 1rem" }}>
          <span
            className="card-title"
            style={{
              fontSize: "2rem",
              fontStyle: "italic",
            }}
          >
            About <span style={{ fontSize: "2.2rem" }}>Snack Delight!</span>
          </span>
        </h2>
        <p className="card-text">
          Welcome to Snack Delight, where every bite is a journey to flavor
          paradise. At Snack Delight, we are passionate about crafting the most
          delectable and savory snacks that will tantalize your taste buds. Our
          commitment to quality, taste, and innovation has made us a go-to
          destination for those seeking the perfect snack to satisfy their
          cravings.
        </p>

        <p>
          Our culinary journey began years ago, rooted in the idea of bringing a
          fusion of flavors to your plate. Each snack at Snack Delight is a
          result of love, tradition, and creativity. We've drawn inspiration
          from diverse culinary traditions to create snacks that are both
          timeless and contemporary.
        </p>

        <p className="card-text">
          At Snack Delight, quality is our utmost priority. We meticulously
          source the finest ingredients to ensure that every snack we serve is
          of the highest quality. Our dedicated chefs and bakers craft each item
          with precision and love, ensuring that your experience is always
          exceptional.
        </p>
      </div>
      <div style={imageStyle}>
        <img
          src={process.env.PUBLIC_URL + "/assets/about-cupcake.jpg"}
          alt="About Snack Delight"
          style={mobileImageStyle} // Use the mobile style for the image on smaller screens
        />
      </div>
    </section>
  );
};

export default About;
