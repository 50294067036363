import React from 'react'
import Carousel from "./Carousel";
import About from "./About";
import Gallery from "./Gallery";

function Home() {
  return (
    <>
        <Carousel/>
        <About/>
        <Gallery/>
    </>
  )
}

export default Home