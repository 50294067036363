import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import "./carousel.css";

import image from './assets/Carousel-img1.jpg'
import image1 from './assets/Carousel-img1.jpg'
import image2 from './assets/Carousel-img1.jpg'

const Slider = () => {
  return (
    <div className="carouselcontainer">
      <Carousel>
        <Carousel.Item interval={3000}>
          <img src={image} alt="whatever" className="carouselimage" />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img src={image1} alt="whatever" className="carouselimage" />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img src={image2} alt="whatever" className="carouselimage" />
        </Carousel.Item>
        
      </Carousel>
    </div>
  );
};

export default Slider;
