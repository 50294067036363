import React from 'react'

function Footer() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffc614",
          color: "#ffc614",
          height: "0.3rem",
          fontSize: "0.2rem",
        }}
      />
      <div
        style={{
          backgroundColor: "#750f34",
          color: "#750f34",
          height: "0.3rem",
          fontSize: "0.2rem",
        }}
      />
      <div class="container text-center" >
        <div
          class="row"
          style={{ margin: "1.5rem 0rem 1rem 0rem", width: "100wh" }}
        >
          <div class="col" style={{ margin: "0.2rem 0rem 0rem 0rem" }}>
            <div style={{ textAlign: "left" }}>© Copyrights 2023-2024,</div>
            <div style={{ textAlign: "left" }}>Snack Delight.</div>
            <p></p>
          </div>
          <div
            className="col"
            style={{ margin: "0", padding: "0 10rem 0 0rem" }}
          >
            <img
              src="favicon.png"
              width="60"
              height="40"
              className="d-inline-block align-text-top"
              style={{ margin: "0.6rem 0 0 6rem" }}
              alt="favicon?"
            />
          </div>

          <div
            class="col"
            style={{ margin: "0rem 0rem 0rem 0rem", textAlign: "right" }}
          >
            <a href="https://wa.me/message/LWXXMDUTMRHQO1" target="_blank">
              <img
                src={process.env.PUBLIC_URL + "/assets/social_whattsapp.png"}
                height="20px"
                width="20px"
                alt="whattsapp?"
                style={{
                  margin: "0.5rem 0.5rem 0.5rem 0rem",
                  textAlign: "right",
                }}
              />
            </a>
            <a
              href="https://instagram.com/snackdelight.blr?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
              target="_blank"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/social_insta.png"}
                height="20px"
                width="20px"
                alt="Insta?"
                style={{ margin: "0rem 0rem 0rem 0rem", textAlign: "right" }}
              />
            </a>
            <div style={{ textAlign: "right" }}>snackdelight.blr</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer