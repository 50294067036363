import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    setTimeout(() => {
      setIsLoaded(true);
    }, 5000); // Adjust the delay as needed
  }, []);

  return (
    <div className="app">
      <Navbar className={isLoaded ? "slide-in visible" : "slide-in"} />
      <Home className={isLoaded ? "slide-in visible" : "slide-in"} />
      <Footer className={isLoaded ? "slide-in visible" : "slide-in"} />
    </div>
  );
}

export default App;
